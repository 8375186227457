import { createReducer, on } from '@ngrx/store';
import * as DataActions from './flight-search.action';
import { FlightSearch } from 'src/app/models/FlightSearch';
import {
  FlightSearchFilter,
  OffersSortType,
} from 'src/app/models/FlightSearchFilters';
import { FlightOffer } from 'src/app/models/autogen-models/flightOffer';
import { Duration } from 'src/app/models/duration';
import { getDurationFromISO } from 'src/app/utils/date-tools';
import { AirlineMap, AirportMap } from 'src/app/models/mapString';

export interface ConnectionPrices {
  zero?: number;
  one?: number;
  twoUp?: number;
}

export interface FilteredFlightOffers {
  total: number;
  filtered: number;
  connectionPrices: ConnectionPrices;
  flightHours: {
    min: Duration;
    max: Duration;
  };
  offers: FlightOffer[];
}

export interface OfferSortState {
  type: OffersSortType;
  priceWeight: number;
  durationWeight: number;
  bestOffers: FlightOffer[];
  cheapestOffers: FlightOffer[];
  fastestOffers: FlightOffer[];
}

export interface FlightSearchState {
  search: FlightSearch | null;
  filter: FlightSearchFilter;
  offers: FlightOffer[];
  filteredOffers: FilteredFlightOffers | null;
  airports: AirportMap;
  airlinesCode?: string[];
  sortedOffers: OfferSortState;
}

const lowerHour = () => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  return date;
};

const upperHour = () => {
  const date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  return date;
};

export const initialState: FlightSearchState = {
  search: {},
  filter: {
    connection0: true,
    connection1: true,
    connection2up: true,
    departureTime: { lower: lowerHour(), upper: upperHour() },
    returnTime: { lower: lowerHour(), upper: upperHour() },
  },
  offers: [],
  filteredOffers: null,
  airports: {},
  sortedOffers: {
    type: OffersSortType.BEST,
    durationWeight: 0.8,
    priceWeight: 0.2,
    bestOffers: [],
    cheapestOffers: [],
    fastestOffers: [],
  },
};

export const selectFilter = (state: FlightSearchState) => state.filter;

export const flightSearchReducer = createReducer(
  initialState,
  on(DataActions.resetSearch, (state) => ({ ...initialState, search: state.search })),
  on(DataActions.setFlightSearch, (state, { search }) => ({
    ...state,
    search,
    filter: updateFilterBasedOnSearch(state.filter, search),
  })),
  on(DataActions.setSearchFilter, (state, { filter }) => {
    const filteredOffers = updateFilteredOffer(state.offers, filter);
    const sortedOffers = getSortedOffers(
      state.sortedOffers,
      filteredOffers.offers
    );
    return {
      ...state,
      filter,
      filteredOffers,
      sortedOffers,
    };
  }),
  on(DataActions.setSearchResult, (state, { offers, airlinesCode }) => {
    const filteredOffers = updateFilteredOffer(offers, state.filter);
    const sortedOffers = getSortedOffers(
      state.sortedOffers,
      filteredOffers.offers
    );
    const filter = { ...state.filter, airlinesCode: airlinesCode };
    return {
      ...state,
      offers,
      filteredOffers,
      sortedOffers,
      airlinesCode,
      filter
    };
  }),
  on(DataActions.updateFilterBasedOnSearch, (state, { search }) => ({
    ...state,
    filter: updateFilterBasedOnSearch(state.filter, search),
  })),
  on(DataActions.setOffersSort, (state, { sortType }) => ({
    ...state,
    sortedOffers: { ...state.sortedOffers, type: sortType },
  }))
);

function setSortedOffers(
  filteredOffers: FilteredFlightOffers | null,
  sort: OfferSortState
): FilteredFlightOffers | null {
  if (!filteredOffers) {
    return null;
  }

  if (sort.type === OffersSortType.BEST) {
    filteredOffers.offers = sort.bestOffers;
  } else if (sort.type === OffersSortType.CHEAPEST) {
    filteredOffers.offers = sort.cheapestOffers;
  } else {
    filteredOffers.offers = sort.fastestOffers;
  }

  return null;
}

function updateFilterBasedOnSearch(
  filter: FlightSearchFilter,
  search: FlightSearch
): FlightSearchFilter {
  const newFilter: FlightSearchFilter = { ...initialState.filter };
  if (!search.returnDate) {
    newFilter.returnTime = undefined;
    return {
      ...newFilter,
      // Update filter properties based on the absence of a return date
    };
  } else {
    newFilter.returnTime = initialState.filter.returnTime;
    // If there is a return date in the search, modify the filter accordingly
    return {
      ...newFilter,
      // Update filter properties based on the presence of a return date
    };
  }
}

function updateFilteredOffer(
  offers: FlightOffer[],
  filter: FlightSearchFilter
): FilteredFlightOffers {
  let maxOfferDuration: Duration = { hours: 0, minutes: 0, seconds: 0 };
  let minOfferDuration: Duration = { hours: 100, minutes: 0, seconds: 0 };
  const connectionPrices: ConnectionPrices = {};

  const filtered = offers.filter((offer) => {
    let departureFlightDate: Date | null = null;
    let returnFlightDate: Date | null = null;
    let maxFlightDuration: Duration = { hours: 0, minutes: 0, seconds: 0 };
    let maxSegment = 1;

    const segmentLengths = offer.itineraries?.map(
      (itinerary) => itinerary.segments.length
    );
    if (segmentLengths) {
      maxSegment = Math.max(...segmentLengths);
    }

    offer.itineraries?.forEach((itinerary, index) => {
      const duration: Duration = getDurationFromISO(itinerary.duration);
      const totalMinutes = duration.hours * 60 + duration.minutes;
      if (
        totalMinutes >
        maxFlightDuration.hours * 60 + maxFlightDuration.minutes
      ) {
        maxFlightDuration = duration;
      }
    });

    if (offer.itineraries) {
      departureFlightDate = offer.itineraries[0].segments[0].departure?.at
        ? new Date(offer.itineraries[0].segments[0].departure.at)
        : new Date();

      if (offer.itineraries.length > 1) {
        returnFlightDate = offer.itineraries[1].segments[0].departure?.at
          ? new Date(offer.itineraries[1].segments[0].departure.at)
          : new Date();
      }
    }

    // Filter match based on the search filters
    const filterMatch =
      ((filter.connection0 && maxSegment === 1) ||
        (filter.connection1 && maxSegment === 2) ||
        (filter.connection2up && maxSegment >= 3)) &&
      ((departureFlightDate !== null &&
        compareTime(departureFlightDate, filter.departureTime.lower) &&
        compareTime(filter.departureTime.upper, departureFlightDate)) ||
        departureFlightDate === null) &&
      ((returnFlightDate !== null &&
        filter.returnTime &&
        compareTime(returnFlightDate, filter.returnTime.lower) &&
        compareTime(filter.returnTime.upper, returnFlightDate)) ||
        !filter.returnTime)
      //   &&
      // ((filter.flightHours &&
      //   maxFlightDuration.hours * 60 + maxFlightDuration.minutes <=
      //   filter.flightHours.hours * 60 + filter.flightHours.minutes) ||
      //   !filter.flightHours)
      // && (
      //   (
      //     offer.validatingAirlineCodes?.filter(
      //       airlineCode => filter.airlinesCodes?.includes(airlineCode)
      //     )?.length || 0
      //   ) > 0
      // );

    // Get flight by min/max duration
    maxOfferDuration = compareDuration(maxOfferDuration, maxFlightDuration)
      ? maxOfferDuration
      : maxFlightDuration;
    minOfferDuration = compareDuration(maxFlightDuration, minOfferDuration)
      ? minOfferDuration
      : maxFlightDuration;

    // Save flight price by segments
    if (offer.price) {
      const offerPrice = Number(offer.price.total);
      if (
        maxSegment === 1 &&
        (!connectionPrices.zero || offerPrice < connectionPrices.zero)
      ) {
        connectionPrices.zero = offerPrice;
      } else if (
        maxSegment === 2 &&
        (!connectionPrices.one || offerPrice < connectionPrices.one)
      ) {
        connectionPrices.one = offerPrice;
      } else if (
        maxSegment >= 3 &&
        (!connectionPrices.twoUp || offerPrice < connectionPrices.twoUp)
      ) {
        connectionPrices.twoUp = offerPrice;
      }
    }

    return filterMatch;
  });

  const filteredOffers: FilteredFlightOffers = {
    total: offers.length,
    filtered: filtered.length,
    offers: filtered,
    connectionPrices,
    flightHours: { min: minOfferDuration, max: maxOfferDuration },
  };
  return filteredOffers;
}

const compareTime = (date1: Date, date2: Date) => {
  // true if date1 hours + minutes >= date2
  const date1minutes = date1.getHours() * 60 + date1.getMinutes();

  const date2minutes = date2.getHours() * 60 + date2.getMinutes();

  return date1minutes >= date2minutes;
};

const compareDuration = (duration1: Duration, duration2: Duration) => {
  return (
    duration1.hours * 60 + duration1.minutes >
    duration2.hours * 60 + duration2.minutes
  );
};

function getSortedOffers(
  sort: OfferSortState,
  offers: FlightOffer[]
): OfferSortState {
  const bestOffers: FlightOffer[] = [...offers].sort((a, b) =>
    sortOffers(a, b, { ...sort, type: OffersSortType.BEST })
  );
  const cheapestOffers: FlightOffer[] = [...offers].sort((a, b) =>
    sortOffers(a, b, { ...sort, type: OffersSortType.CHEAPEST })
  );
  const fastestOffers: FlightOffer[] = [...offers].sort((a, b) =>
    sortOffers(a, b, { ...sort, type: OffersSortType.FASTEST })
  );

  return { ...sort, bestOffers, cheapestOffers, fastestOffers };
}

function sortOffers(
  a: FlightOffer,
  b: FlightOffer,
  sort: OfferSortState
): number {
  if (sort.type === OffersSortType.BEST) {
    const aScore = calculateWeightedScore(
      a,
      sort.priceWeight,
      sort.durationWeight
    );
    const bScore = calculateWeightedScore(
      b,
      sort.priceWeight,
      sort.durationWeight
    );
    if (aScore < bScore) return -1;
    if (aScore > bScore) return 1;
  } else {
    const aPrice = a.price ? Number(a.price.total) : 0;
    const bPrice = b.price ? Number(b.price.total) : 0;
    if (sort.type === OffersSortType.CHEAPEST) {
      if (aPrice < bPrice) return -1;
      if (aPrice > bPrice) return 1;
    } else {
      const aDuration = getOfferAverageDuration(a);
      const bDuration = getOfferAverageDuration(b);
      if (compareDuration(bDuration, aDuration)) return -1;
      if (compareDuration(aDuration, bDuration)) return 1;
      if (aPrice < bPrice) return -1;
      if (aPrice > bPrice) return 1;
    }
  }

  return 0;
}

function getOfferMaxDuration(offer: FlightOffer): Duration {
  let maxFlightDuration: Duration = { hours: 0, minutes: 0, seconds: 0 };

  offer.itineraries?.forEach((itinerary, index) => {
    const duration: Duration = getDurationFromISO(itinerary.duration);
    const totalMinutes = duration.hours * 60 + duration.minutes;
    if (
      totalMinutes >
      maxFlightDuration.hours * 60 + maxFlightDuration.minutes
    ) {
      maxFlightDuration = duration;
    }
  });

  return maxFlightDuration;
}

export function getOfferAverageDuration(offer: FlightOffer): Duration {
  const totalDuration: Duration = { hours: 0, minutes: 0, seconds: 0 };
  const iterationsLength: number = offer.itineraries?.length || 1;

  offer.itineraries?.forEach((itinerary) => {
    const duration = getDurationFromISO(itinerary.duration);
    totalDuration.hours += duration.hours;
    totalDuration.minutes += duration.minutes;
  });

  if (iterationsLength === 1) {
    return totalDuration
  }

  const averageMinutes =
    (totalDuration.hours * 60 + totalDuration.minutes) / iterationsLength;
  const averageDuration: Duration = {
    hours: Math.floor(averageMinutes / 60),
    minutes: 0,
    seconds: 0,
  };
  averageDuration.minutes = averageMinutes - (averageDuration.hours * 60);
  return averageDuration;
}

function calculateWeightedScore(
  offer: FlightOffer,
  priceWeight: number,
  durationWeight: number
) {
  const total: number = Number(offer.price?.markupTotal || 0);
  const averageDuration: Duration = getOfferAverageDuration(offer);
  return (
    total * (1 - priceWeight) +
    (averageDuration.hours * 60 + averageDuration.minutes) * (1 - durationWeight)
  );
}
