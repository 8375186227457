import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModalPage } from './pages/language-modal/language-modal.page';
import { Storage } from '@ionic/storage-angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { register } from 'swiper/element/bundle';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { filter, mergeMap, take } from 'rxjs';
import { SettingsStoreService } from './stores/settings/settings.service';
import * as constants from './constants';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import Clarity from '@microsoft/clarity';
import { environment } from 'src/environments/environment';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from './services/api/authentication.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/api/notification.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'MENU.EXPLORE', url: '/home', icon: 'search-outline' },
    { title: 'MENU.TRIPS', url: '/trips', icon: 'bag-handle-outline' },
    { title: 'MENU.PROFILE', url: '/profile', icon: 'person-circle-outline' },
  ];
  currentUrl = '/home';

  param = { value: 'world' };
  availableLanguages = [
    'es',
    'zh'
  ];

  appName: string = '';
  agencyName: string = '';
  appNameSubtitle: string = '';

  firebaseChecked: boolean = false;
  message: any = null;
  cookieModal: boolean = false;
  isNativePlatform: boolean;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private storage: Storage,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private firebaseAuth: AngularFireAuth,
    private analytics: Analytics,
    private authService: AuthService,
    private settingsStoreService: SettingsStoreService,
    private swUpdate: SwUpdate,
    private afMessaging: AngularFireMessaging,
    private notificationService: NotificationService
  ) {
    this.isNativePlatform = Capacitor.isNativePlatform();

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });

    firebaseAuth.onAuthStateChanged((s) => {
      this.firebaseChecked = true;
    });

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'screen_view');
      }
    });

    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('APP_NAME').pipe(take(1)).subscribe((title) => {
        this.appName = title;
      });
      this.translateService.get('APP_SUBTITLE').pipe(take(1)).subscribe((title) => {
        this.appNameSubtitle = title;
      });
      this.translateService.get('TRAVEL_AGENCY').pipe(take(1)).subscribe((title) => {
        this.agencyName = title;
      });
    });

    if (localStorage.getItem('cookie') !== 'true') {
      if (window.location.pathname !== '/privacy-policy') {
        this.cookieModal = true;
      }
    } else {
      this.enableMicrosoftClarity();
    }
  }

  async ngOnInit() {
    await this.storage.create();
    this.getSettings();

    this.checkLanguage();

    this.routerTitle();
    this.initPush();

    // this.initializeApp();
    // this.checkForPwaUpdates();
  }

  checkForPwaUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate();
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          document.location.reload();
        });
    }
  }
  async openLanguageModal() {
    const modal = await this.modalController.create({
      component: LanguageModalPage,
    });
    return await modal.present();
  }

  checkLanguage() {
    // this.translateService.setDefaultLang('zh');
    // this.translateService.use('zh');
    this.translateService.addLangs(this.availableLanguages);

    const savedLang = localStorage.getItem(constants.LANGUAGE_KEY);
    if (savedLang) {
      this.translateService.use(savedLang);
    } else {
      const browserLang: string = this.translateService.getBrowserLang() || '';
      const setLang: string = this.availableLanguages.includes(browserLang) ? browserLang : 'zh';
      this.translateService.use(setLang);
    }

    this.translateService.get('APP_NAME').pipe(take(1)).subscribe((title) => {
      this.appName = title;
    });
  }

  initializeDeepLinkListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.com').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  async initPush() {
    if (this.isNativePlatform) {
      await this.initNativePush();
    } else {
      await this.initWebPush();
    }
  }

  private async initNativePush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    const permission = await PushNotifications.requestPermissions();

    if (permission.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();
    } else {
      throw new Error('Permission denied for push notifications');
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token) => {
      console.log('Push registration success, token:', token.value);
      // Send this token to your server
      this.sendTokenToServer(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error) => {
      console.error('Error on registration:', error);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push received:', notification);
      // Here you can display the notification in your app
      this.displayNotification(notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push action performed:', notification);
      // Here you can handle the notification tap action
      this.handleNotificationAction(notification);
    });
  }

  private async initWebPush() {
    try {
      const token = await this.afMessaging.requestToken.pipe(
        mergeMap(() => this.afMessaging.tokenChanges)
      ).toPromise();

      console.log('FCM token:', token);
      if (token)
        this.sendTokenToServer(token);

      this.afMessaging.messages.subscribe((payload) => {
        console.log('New message received:', payload);
        this.displayWebNotification(payload);
      });
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  }

  private sendTokenToServer(token: string) {
    // Implement your logic to send the token to your server
    console.log('Sending token to server:', token);
    // Example: this.http.post('/api/register-device', { token });

    this.notificationService.setToken(token);
    this.authService.logged.subscribe(logged => {
      if (logged) {
        this.notificationService.registerToAccount().subscribe();
      }
    });
  }

  private displayNotification(notification: any) {
    // Implement your notification display logic for native
    console.log('Displaying notification:', notification);
  }

  private displayWebNotification(payload: any) {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: '/assets/icons/icon-72x72.png'
    };

    if ('Notification' in window && Notification.permission === 'granted') {
      new Notification(notificationTitle, notificationOptions);
    }
  }

  private handleNotificationAction(notification: ActionPerformed) {
    console.log('Handling notification action:', notification);
    
    const data: any = notification.notification.data
    // console.log("FCM data:", data);
    // if(data.order && data.phone){
    //   this.router.navigate(['/orders', data.order, data.phone]);
    // }
  }

  routerTitle() {
    this.router.events.subscribe((evs) => {
      if (evs instanceof NavigationStart) {
        if (localStorage.getItem('cookie') !== 'true' && evs.url !== '/privacy-policy') {
          this.cookieModal = true;
        } else {
          this.cookieModal = false;
        }
      }

      if (evs instanceof NavigationEnd) {
        let route = this.activatedRoute.firstChild;
        while (route?.firstChild) route = route.firstChild;
        if (route?.outlet === 'primary') {
          route.data.subscribe((data) => {
            data &&
              data['title'] &&
              this.translateService.stream(data['title']).subscribe((title) => {
                this.titleService.setTitle(`${title} - ${this.appName} ${this.appNameSubtitle}`);
              });
          });
        }
      }
    });
  }

  triggerCookie(accept: boolean) {
    if (!accept) {
      this.router.navigate(['/privacy-policy']);
    }
    localStorage.setItem('cookie', `${accept}`);
    this.cookieModal = false;
    this.enableMicrosoftClarity();
  }

  getSettings() {
    const setting_key_values: Array<Array<any>> = [
      [constants.FLIGHT_ORDER_LAST_TICKETING_DATE_LIMIT_KEY, 1],
      [constants.FLIGHT_ORDER_MIN_BOOKING_HOURS_KEY, 48]
    ];
    setting_key_values.forEach(key => {
      this.settingsStoreService.get(key[0], key[1]).subscribe(setting => {
      });
    });
  }

  enableMicrosoftClarity() {
    environment.microsoftClarity && Clarity.init(environment.microsoftClarity);
  }
}
