import { Injectable } from '@angular/core';
import { persistState, Store, StoreConfig } from '@datorama/akita';
import { BookingRequirements, FlightOffer, Issue, Traveler } from 'src/app/models/autogen-models/models';
import { ItinerariesAdditionalBags } from 'src/app/models/FlightAdditionalBags';
import { BookingFormContact } from 'src/app/models/FlightOfferBooking';
import { FlightPriceIncludeBags, OfferFareRules } from 'src/app/models/FlightPriceResponse';

export interface OfferState {
    offer?: FlightOffer,
    expireAt?: Date,
    storedOfferId?: string,
    warnings?: Issue[],
    itineraryWarningChecked: boolean,
}


export function createInitialState(): OfferState {
    return {
        itineraryWarningChecked: false
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'offer' })
export class OfferStore extends Store<OfferState> {
    constructor() {
        super(createInitialState());
    }
}

export const offerPersistStorage = persistState({
    include: ['offer'],
    key: 'offerStore',
    deserialize: (value: any) =>
        JSON.parse(value, (key, val) => {
            return key === 'expireAt' ? val ? new Date(val) : val : val;
        }),
});