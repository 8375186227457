import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as constants from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  availableLanguages = [
    'es',
    'zh'
  ];

  constructor(
    private translateService: TranslateService
  ) { }

  getlanguage() {
    let lang = localStorage.getItem(constants.LANGUAGE_KEY) || this.translateService.currentLang;
    if (!lang) {
      const browserLang = this.translateService.getBrowserLang() || this.translateService.defaultLang;
      lang = this.availableLanguages.includes(browserLang) ? browserLang : this.translateService.defaultLang;
    }
    return lang;
  }
}
