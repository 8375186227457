import { Injectable } from '@angular/core';
import ApiClientService from '../api-client.service';

export interface NotificationRegisterPayload {
  token: string;
  isAccount?: boolean;
  order?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private token: string = '';

  constructor(
    private apiClient: ApiClientService
  ) { }

  setToken(token: string) {
    this.token = token;
  }

  registerToAccount() {
    return this.apiClient.post(
      'notifications/register/account',
      { token: this.token, isAccount: true } as NotificationRegisterPayload,
    { skipToast: true });
  }

  registerToOrder(order: string) {
    return this.apiClient.post(
      'notifications/register/order', 
      { token: this.token, order } as NotificationRegisterPayload,
    { skipToast: true });
  }
}
